<template>
    <section class="tables">
     
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Affecter un projet
                   <router-link to="annonces">
                <span class="float-right btn btn-info">
                  retour
                </span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="addannonce()">
                  <div class="row">


                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Type <span class="obligatoire">*</span>
                        </label>
  
                        <select
                        class="form-control"
                        id="type"
                        v-model="type"
                        @change="onSelectChange(type)"
                      >
                        <option>Article</option>
                        <option>Projet</option>
                      </select>
                      </div>
                    </div>
                    <!--  quantite  -->
                    <div class="col-md-4" id="quantite" style="display: none">
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Quantité<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        id="quantite"
                        v-model="quantite"
                        placeholder="quantite"
                      />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Nom<span class="obligatoire">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="nom"
                        v-model="nom"
                        placeholder="nom"
                        required
                      />
                    </div>
                  </div>


  
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Animatrice <span class="obligatoire">*</span></label
                        >
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="animatrice_id"
                          required
  
                        >
                          <option
                            v-for="user in users"
                            :key="user._id"
                            v-bind:value="user._id"
                          >
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Délégué <span class="obligatoire">*</span></label
                        >
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="delegue_id"
                          required
  
                        >
                          <option
                            v-for="del in delegues"
                            :key="del._id"
                            v-bind:value="del._id"
                          >
                            {{ del.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Date  <span class="obligatoire">*</span></label
                        >
                        <input
                          type="date"
                          class="form-control"
                          placeholder=""
                          id="first"
                          v-model="date"
                          required
  
                        />
                        <span class="text-danger" v-if="error != null">
                    {{ error }}
                      </span>
                      </div>
                    </div>
                    <!--  col-md-6   -->
  
                    
                    <!--  col-md-6   -->
  
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="last"
                          >Client <span class="obligatoire">*</span></label
                        >
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="client_id"
                          required
  
                        >
                          <option
                            v-for="client in clients"
                            :key="client._id"
                            v-bind:value="client._id"
                          >
                            {{ client.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!--  col-md-6   -->
  
                   
                    <!--  col-md-6   -->
                  </div>
  
                  <button type="submit" class="btn btn-primary">
                   + Affecter le projet
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  import moment from 'moment';
  export default {
  
    data() {
      return {
        objectif: {
            nom: "",
            date: "",
            client_id: "",
            delegue_id: "",
            quantite: "",
            type: "",
            animatrice_id: "",

            token : localStorage.getItem("token"),

                },
        delegues: [],
        clients: [],
        users: [],

        error: "",
      };
    },
  
    created() {
      this.getdelegues();
      this.getanimatrices();
      this.getclients();

    },
    methods: {
        getanimatrices() {
        HTTP
          .get("annonces/getanimatrice")
          .then((response) => {
            console.log(response.data);
            this.users = response.data;
          })
          .then((err) => console.log(err));
      },

      getclients() {
        HTTP
          .get("annonces/getclientss")
          .then((response) => {
            console.log(response.data);
            this.clients = response.data;
          })
          .then((err) => console.log(err));
      },

      getdelegues() {
        HTTP
          .get("annonces/getdelegue")
          .then((response) => {
            console.log(response.data);
            this.delegues = response.data;
          })
          .then((err) => console.log(err));
      },
  
      addannonce()
      
      {   let annonce = {
          nom: this.nom,
          date: this.date,
          type: this.type,
          quantite: this.quantite,
          animatrice_id: this.animatrice_id,
          delegue_id: this.delegue_id,
          client_id: this.client_id,
        //   token : localStorage.getItem("token"),

        };

      HTTP.post("annonces/addannonce", annonce).then(
        (response) => {
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: "annonce ajouté",
            });
            this.$router.push("/annonces");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    onSelectChange(data) {
      if (data === "Article") {
        document.getElementById("quantite").style.display = "block";
       
      } else  {
        document.getElementById("quantite").style.display = "none";
        
      }
   
    },


    // getuser() {
    //   var token = localStorage.getItem("token");
    //   HTTP.get("getcurrentuser" + token)
    //     .then((response) => {
    //       this.id = response.data;
    //     })
    //     .then((err) => console.log(err));
    // },
        
} 
    
  };
  </script>
  